import React from "react";
import { diffChars, diffWords } from "diff";

const ParagraphDiff = ({ originalText, modifiedText }) => {
  var options = { ignoreWhitespace: true };
  const differences = diffWords(originalText, modifiedText, options);
  //   const differences = diffChars.diffWordsWithSpace();

  return (
    <>
      {differences.map((part, index) => {
        const style = {
          backgroundColor: part.added
            ? "green"
            : part.removed
              ? "red"
              : "transparent",
        };
        return (
          <span key={index} style={style}>
            {part.value}
          </span>
        );
      })}
    </>
  );
};

export default ParagraphDiff;
