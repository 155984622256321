import "./FileUploader.css"
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PDFViewerLocal from "../PdfViewer/PdfViewerLocal";

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      isDragOver: false,
      unsupportedFileType: false,
    };
  }

  handleClearFile() {
    this.setState({
      selectedFile: null
    });

    ReactGA.event({
      category: 'selectFile',
      action: 'Click',
      label: 'clearall',
    });
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    this.handleSelectedFile(file);
  }

  handleDragOver(event) {
    event.preventDefault();
    this.setState({ isDragOver: true });
  }

  handleDragLeave() {
    this.setState({ isDragOver: false });
  }

  handleDrop(event) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.handleSelectedFile(file);
  }

  handleSelectedFile(file) {
    ReactGA.event({
      category: 'selectFile',
      action: 'Click',
      label: 'fileUploadClicked',
    });
    if (file && file.type === 'application/pdf') {
      this.setState({ selectedFile: file, unsupportedFileType: false });
      // Additional actions can be performed here
      ReactGA.event({
        category: 'file',
        action: 'uploaded',
        label: 'successful',
      });
    } else {
      this.setState({ unsupportedFileType: true });
      ReactGA.event({
        category: 'file',
        action: 'uploaded',
        label: 'not supported',
      });
    }
  }

  render() {
    const { selectedFile, isDragOver, unsupportedFileType } = this.state;

    return (
      <div
        className={`file-uploader ${isDragOver ? 'drag-over' : ''}`}
        onDragOver={this.handleDragOver.bind(this)}
        onDragLeave={this.handleDragLeave.bind(this)}
        onDrop={this.handleDrop.bind(this)}
      >

        {!selectedFile && (
          <input type="file" accept=".pdf" onChange={this.handleFileChange.bind(this)} />
        )}
        {unsupportedFileType && <p className="unsupported-file">Unsupported file type!</p>}        
        {selectedFile && (
          <div>
            <button className="btn btn-primary clearbtn"  onClick={this.handleClearFile.bind(this)}>Reset All</button>
            <PDFViewerLocal pdfUrl={selectedFile} />            
          </div>
        )}
      </div>
    );
  }
}

export default FileUploader;
