import "bootstrap/dist/css/bootstrap.min.css";
import "./PdfViewerLocal.css";
import React, { useEffect, useRef, useState } from "react";
import ReactGA from 'react-ga';
import ParagraphDiff from "../ParagraphDiff";
import { Card, Button, Col, Container, Row, Form } from "react-bootstrap";


const pdfjsLib = await import("pdfjs-dist/build/pdf");
pdfjsLib.GlobalWorkerOptions.workerSrc =
  window.location.origin + "/pdf.worker.min.js";

const SCALE_CONST = 1;

const PdfViewerLocal = ({ pdfUrl }) => {
  const canvasRef = useRef(null);
  const rectOverlayRef = useRef(null);
  const [pdfDocument, setPdfDocument] = useState(null);
  const uploadedFile = useRef(null);
  const isMouseDownRef = useRef(false);
  const startX = useRef(0);
  const startY = useRef(0);
  const selectedRect = useRef(null);
  const paragraphStyle = useRef(null);
  const [selectedText, setSelectedText] = useState("");
  const [selectedOldRect, setSelectedOldRect] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [originalText, setOriginalText] = useState(null);

  const handleChange = (event) => {
    setOriginalText(event.target.value);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let cancelled = false;
    const reader = new FileReader();

    reader.onload = async (event) => {
      const typedArray = new Uint8Array(event.target.result);
      const loadingTask = pdfjsLib.getDocument(typedArray);
      const pdf = await loadingTask.promise;
      // const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
      if (cancelled) return;
      setPdfDocument(pdf);
      uploadedFile.current = pdf
      //console.log("odabrpddf.curr:",pdfDocument.current);
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: SCALE_CONST });

      const canvas = canvasRef.current;
      const canvasContext = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext,
        viewport,
      };
      page.render(renderContext);
    }
    // };


    // renderPDF();
    reader.readAsArrayBuffer(pdfUrl);

    return () => {
      cancelled = true;
      if (pdfDocument) {
        pdfDocument.destroy();
        console.log("brise dokument");
        setPdfDocument(null);
      }
    };
  }, [pdfUrl]);

  const handleMouseDown = (event) => {
    //setIsMouseDown(true);
    isMouseDownRef.current = true;
    // const rect = canvasRef.current.getBoundingClientRect();
    const offsetX = event.offsetX; //- rect.left;
    const offsetY = event.offsetY; //- rect.top;

    startX.current = offsetX;
    startY.current = offsetY;

    // setStartPosition({ x: startX, y: startY });
    console.log("handleMouseDown:", startX.current, startY.current);
    closePopup(true);
  };

  const handleMouseMove = (event) => {
    if (!isMouseDownRef.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const currentX = event.clientX - rect.left;
    const currentY = event.clientY - rect.top;

    const newRect = {
      x: Math.min(startX.current, currentX),
      y: Math.min(startY.current, currentY),
      width: Math.abs(currentX - startX.current),
      height: Math.abs(currentY - startY.current),
    };
    
    setSelectedOldRect(newRect);
    selectedRect.current = newRect;

    const rectOverlay = rectOverlayRef.current;

    if (rectOverlay) {
      const canvas = canvasRef.current;

      const rectOverlayRect = canvas.getBoundingClientRect();
      const rectOverlayX = newRect.x + rectOverlayRect.left + window.scrollX;
      const rectOverlayY = newRect.y + rectOverlayRect.top + window.scrollY;

      rectOverlay.style.left = `${rectOverlayX}px`;
      rectOverlay.style.top = `${rectOverlayY}px`;
      rectOverlay.style.width = `${newRect.width}px`;
      rectOverlay.style.height = `${newRect.height}px`;
    }
  };

  const escKeyPressed = (event) => {
    if (event.key === "Escape" || event.keyCode === 27) {
      // Handle the Esc key press event here
      console.log("Esc key pressed");
      deselectText();
    }
  };

  function deselectText() {
    selectedRect.current = null;
    isMouseDownRef.current = false;
    closePopup();
  }

  const handleMouseUp = () => {
    //setIsMouseDown(false);
    isMouseDownRef.current = false;

    // setSelectedOldRect(selectedRect.current);
    if (selectedRect.current) {
      // pdfjsLib.getDocument(pdfUrl).promise.then((pdf) => {
      console.log("uploadedFile.current:", uploadedFile.current);
      uploadedFile.current.getPage(1).then((page) => {
        page.getTextContent().then((textContent) => {
          const textsInRect = [];
          const { x, y, width, height } = selectedRect.current;
          const scale =
            canvasRef.current.width /
            page.getViewport({ scale: SCALE_CONST }).width;
          for (const item of textContent.items) {
            const { transform, width: itemWidth, height: itemHeight } = item;
            const itemX = transform[4] * scale;
            const itemY =
              canvasRef.current.height - transform[5] * scale - itemHeight;

            if (
              itemX >= x &&
              itemX + itemWidth <= x + width &&
              itemY >= y &&
              itemY + itemHeight <= y + height
            ) {
              var text = item.str;
              const fontName = item.fontName;
              console.log('Font Name:', fontName);

              console.log("odabran text:", text, "EOL JE:", item.hasEOL);

              // var modifikovano = text;

              if (item.hasEOL) {
                text += "\n";
              }
              // console.log("odabran text:", text, "EOL JE:", item.hasEOL);

              // textsInRect.push(modifikovano.split(""));
              textsInRect.push(text);
            }
          }

          console.log("Texts in selected rectangle:", textsInRect);
          // Parse the JSON string into an array
          //var vred = createParagraph(textsInRect);
          const concatenatedString = textsInRect.join("");
          // var vred = textsInRect.join("");
          setSelectedText(concatenatedString);
          console.log(">>>>>>", concatenatedString);
        });
      });
      // });

      paragraphStyle.current = {
        width: `${selectedRect.current.width * 10}px`,
        height: `${selectedRect.current.height * 10}px`,
        backgroundColor: "rgba(140, 140, 140, 0.8)",
      };
      openPopup(true);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("keydown", escKeyPressed);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("keydown", escKeyPressed);
    };
  }, []);

  return (
    <div>      
      <div>
        <canvas id="pdfCanvas" ref={canvasRef}></canvas>
        {selectedRect.current && (
          <div>
            <div
              id="rectOverlay"
              ref={rectOverlayRef}              
            ></div>
          </div>
        )}
      </div>
      {isPopupOpen && (
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <Card>
                <Card.Body>
                  <Card.Title>Selected text</Card.Title>
                  <Card.Text>{selectedText}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col">
              <Card>
                <Card.Body>
                  <Card.Title>Original text</Card.Title>
                  <Card.Text>                    
                      <Form.Control
                        as="textarea"
                        rows={selectedText.split("\n").length}
                        onChange={handleChange}
                        placeholder="Please insert text"
                      />                    
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col">
              {originalText && selectedText && (
                <Card>
                  <Card.Body>
                    <Card.Title>Diff</Card.Title>
                    <Card.Text>                      
                      <ParagraphDiff
                        originalText={originalText}
                        modifiedText={selectedText}
                      />                      
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfViewerLocal;
