import './App.css';
import FileUploader from './components/FileUploader/FileUploader';
import ReactGA from 'react-ga';

ReactGA.initialize('G-PKF596WHMP');

function App() {

  return (    
    <div className="App">      
      <FileUploader />
    </div>

  );
}

export default App;
